<script>
	import LinkButton from '../../components/LinkButton.svelte';
	import Section from '../../components/Section.svelte';
	import Stars from '../../components/Stars.svelte';
	import { products } from '../../config';
	// original SVG animated by css split in 3 layers
	import HeaderAnimation from './header_animation.svelte';
</script>

<Section class="pt-8">
	<div slot="background" class="absolute w-full h-full"><Stars /></div>
	<div class="relative">
		<div
			class="absolute h-full -translate-y-[30%] lg:-translate-y-[20%] xl:-translate-y-[30%] w-full -translate-x-[20%] sm:-translate-x-[15%]"
		>
			<div class="absolute w-[140%] -mx-[20%] -translate-x-[5%]">
				<HeaderAnimation />
			</div>
		</div>

		<div class="relative py-12 lg:pt-32 md:pb-60">
			<div
				class="text-center sm:text-left flex flex-col items-center sm:items-start w-full font-workSans"
			>
				<h1 class="text-4xl lg:text-7xl max-w-sm sm:max-w-none font-bold font-workSans">
					Restore creative
					<br />
					balance in the Galaxy.
				</h1>
				<h2 class="text-lg xl:text-xl py-8 2xl:py-16">Your Content. Your Terms.</h2>
				<LinkButton href={products.url}>See our products</LinkButton>
			</div>
		</div>
	</div>
	<div class="relative flex w-100 justify-center items-center py-16 md:py-28 text-center">
		<img class="hidden md:block top-0 right-1/4 absolute w-1/10" src="/star.svg" alt="star" />
		<img class="hidden md:block bottom-0 left-1/4 absolute w-1/10" src="/star.svg" alt="star" />
		<div class="w-5/6 font-mono text-xl md:text-2xl">
			Pioneering tech for a creative economy that values creativity and respects everyone's
			intellectual property.
		</div>
	</div>
</Section>
