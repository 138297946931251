<script lang="ts">
	import InfoCard from '../../components/InfoCard.svelte';
	import LinkButton from '../../components/LinkButton.svelte';
	import Section from '../../components/Section.svelte';
	import { anagolayUrl, maculaUrl, photographyPilot, products } from '../../config';
	import IconOpenInNew from './Icon_open_in_new.svelte';

	const cardItems = {
		top: [
			{
				title: 'Protect your work',
				text: 'Kelp relies on cryptography to create legally binding digital certificates for proving your intellectual property rights.'
			},
			{
				title: 'Stay in charge when sharing',
				text: "Stay flexible and don't compromise, having full control over who uses your content and on what terms."
			},
			{
				title: 'Be a part of the change',
				text: "A creative market should be transparent and open for everyone to participate, contribute, and earn. Let's drive the change."
			}
		],
		bottom: [
			{
				title: 'Macula',
				url: maculaUrl,
				text: 'A feature-packed multimedia management platform with a unique approach to sharing while safeguarding copyright.'
			},
			{
				title: 'Anagolay Network',
				url: anagolayUrl,
				text: 'A process-based P2P framework for verifying, storing, and transferring intellectual property rights.'
			},
			{
				title: 'Kelp Marketplace',
				text: 'A decentralized marketplace for a new creative economy that makes licensing creative assets seamless, transparent, and fair.'
			}
		]
	};
</script>

<Section>
	<div
		id="kelp-tech-for-the-galaxy"
		class="relative flex flex-col w-full items-center -mt-16 pt-16 2xl:pt-12"
	>
		<div class="absolute bottom-0 h-3/4 w-[100%]" />
		<div class="relative flex flex-col items-center">
			<div class="flex flex-col md:flex-row gap-4 py-8 md:py-16">
				{#each cardItems.top as data}
					<InfoCard class="md:basis-1/3">
						<div slot="title" class="h-8 md:h-16 xl:h-8 font-medium">
							{data.title}
						</div>
						<div slot="content" class="w-5/6 font-normal">{data.text}</div>
					</InfoCard>
				{/each}
			</div>
			<LinkButton href={photographyPilot.url}>For photographers</LinkButton>
		</div>
		<div class="relative flex flex-col items-center pt-32 md:pt-64 md:pb-20">
			<div class="absolute w-full -translate-y-[10%] md:-translate-y-[25%] h-1/2">
				<div class="absolute pt-[55%] w-full -translate-y-[15%] bg-purpleBright" />
				<div
					class="absolute pt-[55%] w-full rounded-[100%] bg-gradient-to-b from-base-200 via-base-100 to-base-100"
				/>
				<div
					class="absolute pt-[100%] w-full -translate-y-[10%] bg-gradient-to-r from-base-100 via-transparent to-base-100"
				/>
				<div
					class="absolute pt-[50%] w-full bg-gradient-to-t from-base-100 via-base-100 to-transparent"
				/>
			</div>
			<a href="#kelp-tech-for-the-galaxy" class="hover:underline text-info">
				<h2
					class="relative max-w-xs sm:max-w-full textGradient text-2xl md:text-5xl font-workSans font-bold leading-normal text-center py-2"
				>
					Kelp tech for the Galaxy
				</h2>
			</a>

			<div class="flex flex-col md:flex-row gap-4 py-8 sm:py-16">
				{#each cardItems.bottom as data}
					<InfoCard class="md:basis-1/3 group">
						<div slot="title" class="h-8 md:h-16 lg:h-8 font-medium">
							{#if data.url}
								<a class="flex items-center" href={data.url} target="_blank" rel="noreferrer">
									{data.title}
									<IconOpenInNew
										class="opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute -right-[26px] text-primary"
										width="24"
									/>
								</a>
							{:else}
								{data.title}
							{/if}
						</div>

						<div slot="content" class="w-5/6 font-normal">{data.text}</div>
					</InfoCard>
				{/each}
			</div>
			<LinkButton href={products.url}>See All Products</LinkButton>
		</div>
	</div>
</Section>
