<script lang="ts">
	import SvelteSeo from 'svelte-seo';

	import { contentLinks, twitterHandler } from '../config';
	import Cards from '../sections/home/Cards.svelte';
	import Header from '../sections/home/Header.svelte';
	import Restore from '../sections/home/Restore.svelte';
	import Waitlist from '../sections/home/Waitlist.svelte';
</script>

<SvelteSeo
	title={contentLinks.home.title}
	description={contentLinks.home.description}
	keywords={contentLinks.home.keywords}
	openGraph={{
		title: contentLinks.home.title,
		description: contentLinks.home.description,
		url: contentLinks.home.url,
		type: 'website',
		image: contentLinks.home.imageUrl,
		images: [
			{
				url: contentLinks.home.imageUrl,
				width: 1200,
				height: 720,
				alt: contentLinks.home.imageAlt
			}
		],
		site_name: contentLinks.home.siteName
	}}
	twitter={{
		site: twitterHandler,
		title: contentLinks.home.title,
		description: contentLinks.home.description,
		image: contentLinks.home.imageUrl,
		imageAlt: contentLinks.home.imageAlt
	}}
/>

<Header />
<Cards />
<Restore />
<Waitlist />
