<script>
	import Section from '../..//components/Section.svelte';
</script>

<Section class="text-center ">
	<div class="py-16 md:pt-8 md:pb-32 text-center">
		<div class="relative max-w-sm sm:max-w-full flex items-center mx-auto">
			<div class="absolute w-1/3 left-0 -translate-x-[40%] translate-y-[30%]">
				<div class="w-3/5 mx-auto animate-glow bg-purpleBright opacity-50 pt-[60%]" />
			</div>

			<img
				width="1248"
				height="449"
				class="absolute w-full"
				src="/restore_balance.svg"
				alt="orbit with stars"
			/>

			<h1
				class="relative py-[15%] sm:py-36 text-2xl sm:text-4xl lg:text-7xl font-bold font-workSans max-w-4xl md:max-w-none mx-auto"
			>
				Ready to restore the creative balance in the galaxy?
			</h1>
		</div>
	</div>
</Section>
